import React, { useState, useEffect, useMemo } from 'react';
import './App.css';

// Move calculateTimeLeft outside the component
const calculateTimeLeft = (targetDate) => {
  const now = new Date();
  const difference = targetDate - now;

  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

function App() {
  // Use useMemo to memoize the targetDate, so it only gets initialized once
  const targetDate = useMemo(() => new Date('2024-12-31T23:59:59'), []);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]); // targetDate is now stable and will not cause re-renders

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="coming-soon-container">
      <h1>Coming Soon!</h1>
      <p>We are working hard to bring something awesome to education.</p>
      {timerComponents.length ? (
        <h2>{timerComponents}</h2>
      ) : (
        <h2>Launched!</h2>
      )}
    </div>
  );
}

export default App;
// import React, { useState, useEffect } from 'react';
// import './App.css';

// function App() {

//   const targetDate = new Date('2024-12-31T23:59:59');
//   const [timeLeft, setTimeLeft] = useState(10);

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
//     }, 1000);

//     return () => clearInterval(timer); // cleanup
//   }, []);

//   return (
//     <div className='coming-soon-container'>
//       <h1>Coming Soon!</h1>
//       <p>We are working hard to bring something awesome in education.</p>
//       <h2>{timeLeft > 0 ? `Launching in ${timeLeft} seconds...` : 'Launched!'}</h2>
//     </div>
//   );
// }

// export default App;


// import React, { useState, useEffect } from 'react';
// import './App.css';

// function App() {
//   // Set the target date here (e.g., December 31, 2024, 23:59:59)
//   const targetDate = new Date('2024-12-31T23:59:59');

//   const calculateTimeLeft = () => {
//     const now = new Date();
//     const difference = targetDate - now;

//     let timeLeft = {};

//     if (difference > 0) {
//       timeLeft = {
//         days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//         hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//         minutes: Math.floor((difference / 1000 / 60) % 60),
//         seconds: Math.floor((difference / 1000) % 60),
//       };
//     }

//     return timeLeft;
//   };

//   const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setTimeLeft(calculateTimeLeft());
//     }, 1000);

//     return () => clearInterval(timer);
//   }, [calculateTimeLeft]);

//   const timerComponents = [];

//   Object.keys(timeLeft).forEach((interval) => {
//     if (!timeLeft[interval]) {
//       return;
//     }

//     timerComponents.push(
//       <span key={interval}>
//         {timeLeft[interval]} {interval}{" "}
//       </span>
//     );
//   });

//   return (
//     <div className="coming-soon-container">
//       <h1>Coming Soon!</h1>
//       <p>We are working hard to bring something awesome.</p>
//       {timerComponents.length ? (
//         <h2>{timerComponents}</h2>
//       ) : (
//         <h2>Launched!</h2>
//       )}
//     </div>
//   );
// }

// export default App;

